// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("atomicjs")
require("home")

import '../stylesheets/application.scss'

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll("select").forEach(function(elem) {
    elem.addEventListener("change", function(event) {
      const basePath = window.location.href.split('?')[0];
      const resetStates = ["all", "Team", "Position", "mlb", "League", "Age"]
      event.preventDefault();
      if (resetStates.indexOf(elem.value) > -1) {
        window.location.href = basePath;
      } else {
        if (elem.id == "team_filter") {
          window.location.href = `${basePath}?team=${elem.value}`;
        } else if (elem.id == "league_filter") {
          window.location.href = `${basePath}?league=${elem.value}`;
        } else if (elem.id == "age_filter") {
          window.location.href = `${basePath}?age=${elem.value}`;
        } else if (elem.id == "size_filter") {
          window.location.href = `${basePath}?league_size=${elem.value}`;
        } else {
          window.location.href = `${basePath}?pos=${elem.value}`;
        }
      }
    });
  });

  document.querySelector("#player_search").addEventListener("submit", function(event) {
    const basePath = window.location.href.split('?')[0];
    event.preventDefault();
    const query = document.querySelector("#player_search_q").value;
    window.location.href = `${basePath}?q=${query}`;
  });

  document.querySelectorAll(".leaderboards").forEach(function(elem) {
    elem.addEventListener("click", function(event) {
      const basePath = window.location.href.split('?')[0];
      event.preventDefault();
      const stat = elem.className.split(" ")[3];
      window.location.href = `${basePath}?leaderboard_${stat}=1`;
    });
  });
});
