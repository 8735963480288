document.addEventListener("turbolinks:load", function() {
  const triggers = document.querySelectorAll(".off-canvas-toggle, .off-canvas-overlay");
  const sidebar = document.querySelector(".off-canvas-sidebar");

  for(trigger of triggers) {
    trigger.addEventListener("click", function(e) {
      e.preventDefault();
      sidebar.classList.toggle("active");
    });
  }
});
